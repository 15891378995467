import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import { store } from "./store";
import { Provider } from "react-redux";
import Loader from "./components/Loading";
import Cards1 from "./components/tools/Businesscard/Cards/Cards1";
import Cards2 from "./components/tools/Businesscard/Cards/Cards2";
import Cards3 from "./components/tools/Businesscard/Cards/Cards3";

import LoginSheredLayout from "./components/Auth/Login/LoginLayout";
import LoginBtns from "./components/Auth/Login/loginBtns";
import EmailLogin from "./components/Auth/Login/EmailContainer";
import SignupSheredLayout from "./components/Auth/SignUp/signupLayout";
import SignupBtns from "./components/Auth/SignUp/signupBtns";
import EmailSignup from "./components/Auth/SignUp/emailContainer";
import PhoneLogin from "./components/Auth/Login/PhoneContainer";
import "./App.css";
import Profile from "./components/Profile/profileLayout";
import ProfilePage from "./components/Profile/profilePage/profilePage";
import Subscription from "./components/Profile/Subscription/subscription";
import Payment from "./components/Profile/Payment/payment";
import Contact from "./components/Profile/Contact/contact";
import Terms from "./components/Profile/Terms/terms";
import ToolsSharedlayout from "./components/tools/ToolsSharedlayout";
import BusinessCardList from "./components/tools/Businesscard/Cards/BusinessCardList";
import StampList from "./components/tools/BusinessStamp/StampList";
import Stamps1 from "./components/tools/BusinessStamp/Stamp1";
import Stamps2 from "./components/tools/BusinessStamp/Stamp2";
import AddBlog from "./components/tools/blog/blog";
import Editor from "./components/Editor/editor";
import SocialMediaCard from "./components/tools/SocialMediaPosts/SocialMediaCard";
import BillbookLayout from "./components/tools/Billbook/billbookLayout";
import Bills from "./components/tools/Billbook/Dashboard/billbookDashboard";
import BillForm from "./components/tools/Billbook/BillForm/BillForm";
import Note from "./components/start/note";
import Admin from "./components/Profile/admin/admin";
import LandingPage from "./components/Emitra/landingPage";
import EmitraIndex from "./components/Emitra/landingPage/index";
import Download from "./components/utils/download";
import Test from "./components/test";
import Appointment from "./components/Profile/appointment/appointment";
import Card from "./components/Profile/card/card";

// Striepe
import StripePaymentForm from "./stripe/payment";

const Template = React.lazy(() => import("./components/Template"));
const PhoneSignup = React.lazy(() =>
  import("./components/Auth/SignUp/phoneContainer")
);
const Domain = React.lazy(() => import("./components/start/Domain"));
const BusinessDetails = React.lazy(() =>
  import("./components/start/BusinessDetails")
);
const FirstProduct = React.lazy(() =>
  import("./components/start/FirstProduct")
);
const PaymentSetUp = React.lazy(() =>
  import("./components/start/PaymentSetUp")
);
const CatelogSharedLayout = React.lazy(() =>
  import("./components/Catelog/catlogueSharedLayout")
);
const Products = React.lazy(() =>
  import("./components/Catelog/Products/products")
);
const Categorys = React.lazy(() =>
  import("./components/Catelog/Categories/categories")
);
const Bulk = React.lazy(() => import("./components/Catelog/Bulk/bulk"));
const AdminLogin = React.lazy(() => import("./components/Auth/adminLogin"));
const PhonePayPayment = React.lazy(() => import("./components/payment"));
const AppSumoLanding = React.lazy(() =>
  import("./components/appSumo/landingPage")
);
const Offer = React.lazy(() => import("./components/offer"));

function App() {
  return (
    <Provider store={store}>
      <React.Suspense
        fallback={
          <div className="loading-bg-container">
            <Loader />
          </div>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginSheredLayout />}>
              <Route index element={<EmailLogin />} />
              <Route path="phone" element={<PhoneLogin />} />
            </Route>
            <Route path="/signup" element={<SignupSheredLayout />}>
              <Route index element={<EmailSignup />} />
              <Route path="phone" element={<PhoneSignup />} />
            </Route>
            <Route path="/domain" element={<Domain />} />
            <Route path="/bussiness" element={<BusinessDetails />} />
            <Route path="/product" element={<FirstProduct />} />
            <Route path="/payment" element={<PaymentSetUp />} />
            <Route path="/note" element={<Note />} />
            {/* <Route path="/offer" element={<Offer />} /> */}

            <Route path="/" element={<Template />} />
            <Route path="/catalogue" element={<CatelogSharedLayout />}>
              <Route index element={<Categorys />} />
              <Route path="products" element={<Products />} />
              <Route path="bulk" element={<Bulk />} />
            </Route>
            <Route path="/editor" element={<Editor />} />
            <Route path="/tools" element={<ToolsSharedlayout />}>
              <Route index element={<BusinessCardList />} />
              <Route path="card1" element={<Cards1 />} />
              <Route path="card2" element={<Cards2 />} />
              <Route path="card3" element={<Cards3 />} />
              {/* <Route path='card4' element={<Cards4/>} /> */}
              <Route path="businessStamp" element={<StampList />} />
              <Route path="stamp1" element={<Stamps1 />} />
              <Route path="stamp2" element={<Stamps2 />} />
              <Route path="socialposts" element={<SocialMediaCard />} />
              AddBlog
              <Route path="addblog" element={<AddBlog />} />
              {/* <Route path='billbook' element={<BillbookLayout />}/> */}
              <Route path="billbook" element={<BillbookLayout />}>
                <Route index element={<Bills />} />
                <Route path="billform" element={<BillForm />} />
              </Route>
            </Route>
            <Route path="/profile" element={<Profile />}>
              <Route index element={<ProfilePage />} />
              <Route path="subscription" element={<Subscription />} />
              <Route path="payment" element={<Payment />} />
              <Route path="contact" element={<Contact />} />
              <Route path="terms" element={<Terms />} />
              <Route path="admin" element={<Admin />} />
              <Route path="appointment" element={<Appointment />} />
              <Route path="card" element={<Card />} />
            </Route>
            {/* <Route path="/emitra/:kioskcode/:ssoid" element={<LandingPage />} /> */}
            <Route path="/emitra/:kioskcode/:ssoid" element={<EmitraIndex />} />

            <Route path="/download" element={<Download />} />
            <Route path="/test" element={<Test />} />
            <Route path="/adminlogin" element={<AdminLogin />} />
            <Route path="/lifetime" element={<PhonePayPayment />} />
            <Route path="/appsumolanding/:code" element={<AppSumoLanding />} />
            <Route path="/stripepayment" element={<StripePaymentForm />} />
          </Routes>
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  );
}

export default App;
