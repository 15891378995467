import React from "react";
import axios from "axios";

const StripePaymentForm = () => {
  const handleCheckout = async () => {
    try {
      const response = await axios.post(
        "https://backend.digistall.in/stripe/checkout-session",
        {
          amount: 5000, // Amount in cents
        }
      );
      // Redirect to the Stripe Checkout page
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return <button onClick={handleCheckout}>Pay with Stripe</button>;
};

export default StripePaymentForm;
