import React, { useEffect, useState } from "react";
import axios from "axios";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa"; // For delete icon
import { FaArrowRight } from "react-icons/fa";

function Blog() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState([""]);
  const [tags, setTags] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [message, setMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const rlang = useSelector((state) => state.user.lang);
  const shopId = useSelector((state) => state.user.userData.shopId);

  const handleAddContent = () => {
    setContent([...content, ""]);
  };

  const handleContentChange = (index, value) => {
    const updatedContent = content.map((item, i) =>
      i === index ? value : item
    );
    setContent(updatedContent);
  };

  const handleRemoveContent = (index) => {
    const updatedContent = content.filter((_, i) => i !== index);
    setContent(updatedContent);
  };

  const handleImageUpload = async () => {
    if (!coverImage) return "";
    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `blogs/${Date.now()}-${coverImage.name}`);
    await uploadBytes(storageRef, coverImage);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!coverImage) return setMessage("Please select an image");
      if (!title) return setMessage("Please enter a title");
      if (!description) return setMessage("Please enter a description");
      if (!content) return setMessage("Please enter some content");
      if (!tags) return setMessage("Please enter some tags");

      const coverImageUrl = await handleImageUpload();

      const newBlog = {
        title,
        description,
        content,
        tags: tags.split(",").map((tag) => tag.trim()),
        coverImage: coverImageUrl,
        shopId: shopId,
      };
      console.log(newBlog, "newBlog");
      const res = await axios.post(
        "https://gotanbackend.digistall.in/api/blog/addblog",
        newBlog
      );
      if (res.status === 201) {
        // setMessage("Blog added successfully!");
        // Reset form
        setTitle("");
        setDescription("");
        setContent([""]);
        setTags("");
        setCoverImage(null);
        setShowForm(false);
        setUploading(false);
        setMessage("");
      }
    } catch (error) {
      console.error("Error adding blog:", error);
      setMessage("Failed to add blog.");
    }
  };

  //@ My blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `https://gotanbackend.digistall.in/api/blog/${shopId}`
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();
  }, [shopId, showForm]);

  const handleDelete = async (id) => {
    console.log("Deleting blog:", id);
    const response = await axios.delete(
      `https://gotanbackend.digistall.in/api/blog/${id}`
    );

    console.log("Response:", response);

    if (response.data.msg === "Blog deleted successfully") {
      console.log("deleted");
      setBlogs(blogs.filter((blog) => blog._id !== id));
      setShowConfirm(false);
    }
    setShowConfirm(false);
  };

  return (
    <>
      {!showForm ? (
        <div className="relative bg-gray-100 h-max">
          {/* Top-right button */}
          <div className="absolute top-4 right-4">
            <button
              onClick={() => setShowForm(true)}
              className="bg-theme-blue text-white px-6 py-3 rounded-lg hover:bg-blue-700 shadow-md transition duration-300"
            >
              {rlang === "hi" ? "नया ब्लॉग जोड़ें" : "Add New Blog"}
            </button>
          </div>

          {/* Main content */}
          <div className="flex flex-col h-full">
            <h1 className="text-2xl font-bold text-theme-blue mb-8 p-4">
              {rlang === "hi" ? "मेरे ब्लॉग" : " My Blogs"}
            </h1>
            <div className="w-11/12 mx-auto gap-3">
              {blogs &&
                blogs.map((blog) => (
                  <div
                    key={blog.slug}
                    className="flex flex-col mb-2 md:flex-row bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="flex-1 p-4">
                      <h2 className="text-xl font-semibold mb-2">
                        {blog.title}
                      </h2>
                      <div className="text-sm text-gray-500 mb-4">
                        <span>• {blog.createdAt}</span>
                      </div>
                      {/* Check it now button with arrow icon */}
                      <a href={`https://digistall.in/blog/${blog.slug}`}>
                        <div className="flex items-center text-blue-500 font-medium cursor-pointer">
                          <span>Check it now</span>
                          <FaArrowRight className="ml-2" />
                        </div>
                      </a>
                    </div>

                    <div className="w-full md:w-[200px] h-[150px] md:h-auto relative">
                      <img
                        src={blog.coverImage}
                        alt={blog.title}
                        className="w-full h-full object-cover"
                      />
                      <button
                        onClick={() => setShowConfirm(true)}
                        className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                    {/* Confirmation Popup */}
                    {showConfirm && (
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                          <p className="mb-4">
                            {rlang === "hi"
                              ? "क्या आप वाकई इस ब्लॉग को हटाना चाहते हैं?"
                              : "Are you sure you want to delete this blog?"}
                          </p>
                          <div className="flex justify-center space-x-4">
                            <div
                              onClick={() => handleDelete(blog._id)}
                              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors cursor-pointer"
                            >
                              {rlang === "hi" ? "हां, हटाएं" : "Yes, Delete"}
                            </div>
                            <button
                              onClick={() => setShowConfirm(false)}
                              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors"
                            >
                              {rlang === "hi" ? "रद्द करें" : "Cancel"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {blogs.length === 0 && (
                <div className="text-center h-screen mb-4">
                  <div className="text-2xl font-semibold text-gray-900 mb-2">
                    No Blogs Found
                  </div>
                  <div className="text-lg text-gray-700 mb-4">
                    {rlang === "hi"
                      ? "अपनी दुकान को दृश्यमान बनाने और अधिक ग्राहकों को आकर्षित करने के लिए एक ब्लॉग जोड़ें!"
                      : "Add a blog to make your shop visible and attract more customers!"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        //@ Blog Form Here
        <div className="w-full pb-10">
          <form
            onSubmit={handleSubmit}
            className="bg-white p-8 w-11/12 rounded-lg shadow-lg"
          >
            <h2 className="text-3xl font-extrabold mb-6 text-gray-800">
              Add Blogs to Increase your shop's visibility
            </h2>

            <div className="mb-6">
              <label
                className="block text-sm font-medium text-gray-700 mb-2"
                htmlFor="title"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-sm font-medium text-gray-700 mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                rows="3"
                required
              ></textarea>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Content
              </label>
              {content.map((paragraph, index) => (
                <div key={index} className="mb-4 flex items-start">
                  <textarea
                    value={paragraph}
                    onChange={(e) => handleContentChange(index, e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent mr-3"
                    rows="3"
                    required
                  ></textarea>
                  {content.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveContent(index)}
                      className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddContent}
                className="bg-theme-blue text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Add Paragraph
              </button>
            </div>

            <div className="mb-6">
              <label
                className="block text-sm font-medium text-gray-700 mb-2"
                htmlFor="tags"
              >
                Tags (comma separated)
              </label>
              <input
                type="text"
                id="tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-sm font-medium text-gray-700 mb-2"
                htmlFor="coverImage"
              >
                Cover Image
              </label>
              <input
                type="file"
                id="coverImage"
                onChange={(e) => setCoverImage(e.target.files[0])}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            {uploading && (
              <div className="mb-6 text-center text-blue-600 font-semibold">
                Uploading image, please wait...
              </div>
            )}

            {message && (
              <div className="mb-6 text-center text-green-600 font-semibold">
                {message}
              </div>
            )}

            <div className="pb-4 w-full flex justify-between">
              <button
                type="button"
                onClick={() => setShowForm(false)}
                className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700"
              >
                Close Form
              </button>
              <button
                type="submit"
                className="bg-green-600 text-white px-4 py-3 rounded-lg hover:bg-green-700 transition duration-300"
                disabled={uploading}
              >
                {uploading ? <>Wait</> : <>Add Blog</>}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default Blog;
