import axios from "axios";
const url = "https://backend.digistall.in";
// const url = "http://localhost:5000"

export async function getUserData(userId) {
  const response = await axios.post(`${url}/user/getUser`, { userId: userId });
  return response;
}

export async function checkAvailability(domainName) {
  const availability = await axios.post(`${url}/subdomains/checksubdomain`, {
    subdomain: domainName,
  });
  return availability;
}

export async function createsubdomain(domainName, userName, shopId) {
  const response = await axios.post(`${url}/subdomains/createsubdomain`, {
    subdomain: domainName,
    user: userName,
    oldSubdomain: shopId,
  });
  return response;
}

export async function updateUserStatus(userId, userStatus) {
  const response = await axios.post(`${url}/user/userStatus`, {
    userId,
    userStatus,
  });
  return response;
}

export async function createStore(
  shopId,
  businessName,
  userId,
  email,
  businessAddress,
  validTill,
  shopType
) {
  const bannerUrl = [
    "https://res.cloudinary.com/aguruprasad/image/upload/v1670315929/woodline/product-images/Rectangle_35_f4ki6o.png",
  ];
  const body = {
    shopId: shopId,
    shopName: businessName,
    shopLogo: "",
    shopOwnerId: userId,
    shopCategory: undefined,
    shopType: shopType,
    settings: {
      header: {
        logo: "https://res.cloudinary.com/aguruprasad/image/upload/v1669717427/woodline/product-images/Logo_2_xastkj.png",
        shopName: businessName,
        isShopNameVisible: true,
        tagline: "We Provide The Best Service",
        isTaglineVisible: false,
        email: email,
        phoneNumber: "+919999999999",
      },
      welcomeBanner: {
        welcomeTitle: "Welcome To Our Store",
        bannerImages: bannerUrl,
      },
      footer: {
        address: businessAddress,
        isAddressVisible: true,
        copyrightMessage: "(c) ALL RIGHTS RESERVED | HRI",
        isSocialSitesVisible: true,
        facebookUrl: "www.facebook.com",
        instgramUrl: "www.instagram.com",
        youtubeUrl: "www.youtube.com",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
      },
      team: [],
      buttons: [],
      termsAndConditions: {
        termsAndConditions: "NO Terms & Cunditions.",
        msmeCertificate: "www.blankpage.com",
      },
    },
    categorys: [
      {
        categoryId: "jshg726uweho28",
        categoryImg:
          "https://res.cloudinary.com/aguruprasad/image/upload/v1669871942/woodline/product-images/allProducts_fyituv.png",
        gst: 0,
        categoryName: "All Categorys",
      },
    ],
    products: [],
    upiId: "",
    QRCode: "",
    paymentMethod: "PAY ON DELIVERY ONLY",
    gstNumber: "",
    validTill: validTill,
  };
  const response = await axios.post(`${url}/shop/createshop`, body);
  return response;
}

export async function updateStore(shop) {
  const response = await axios.post(`${url}/shop/updateshop`, shop);
  return response;
}

export async function addProduct(shopId, product) {
  const body = {
    shopId,
    product,
  };
  const response = await axios.post(`${url}/shop/addproduct`, body);
  return response;
}

export async function updateProduct(shopId, product) {
  const body = {
    shopId,
    product,
  };
  const response = await axios.post(`${url}/shop/updateproduct`, body);
  return response;
}

export async function deleteProduct(shopId, productId) {
  const response = await axios.delete(`${url}/shop/deleteproduct`, {
    data: { shopId, productId },
  });
  return response;
}

export async function addCategory(shopId, category) {
  const body = {
    shopId,
    category,
  };
  await axios.post(`${url}/shop/addcategory`, body);
}

export async function updateCategory(shopId, category) {
  const body = {
    shopId,
    category,
  };
  await axios.post(`${url}/shop/updatecategory`, body);
}

export async function deleteCategory(shopId, categoryId, categoryName) {
  await axios.delete(`${url}/shop/deletecategory`, {
    data: { shopId, categoryId, categoryName },
  });
}

export async function updateOrderStatus(
  email,
  shopId,
  orderId,
  orderStatus,
  orderNote
) {
  let body;
  if (orderNote) {
    body = {
      email,
      shopId,
      orderId,
      orderStatus,
      orderNote,
    };
  } else {
    body = {
      email,
      shopId,
      orderId,
      orderStatus,
    };
  }
  const response = await axios.post(`${url}/user/changeorderstatus`, body);
  return response;
}

export async function updatePayment({ shopId, QRCode, upiId, cod }) {
  const body = {
    shopId,
    QRCode,
    upiId,
    cod,
  };
  console.log("body", body);
  const response = await axios.post(`${url}/shop/updatepayment`, body);
  return response;
}

export async function updatePayment2(shopId, QRCode, upiId, cod) {
  const body = {
    shopId,
    QRCode,
    upiId,
    cod,
  };
  console.log("body", body);
  const response = await axios.post(`${url}/shop/updatepayment`, body);
  return response;
}

export const generateBill = async (address, products, total) => {
  const date = new Date();
  const updatedProducts = products.map((eachProduct) => ({
    ...eachProduct,
    address,
    totalCartValue: total,
    date,
  }));
  const body = {
    orders: updatedProducts,
  };
  const response = await axios.post(`${url}/user/generatebill`, body);
  return response;
};

export async function sendMail(name, email, message) {
  const body = {
    name,
    email,
    message,
  };
  const response = await axios.post(`${url}/customer/contactus`, body);
  return response;
}

export async function sendPaymentReq(requestData) {
  const response = await axios.post(`${url}/shop/paymentreq`, requestData);
  return response;
}

export async function subscribeStore(paymentData) {
  const response = await axios.post(`${url}/shop/adminapi`, {
    secretKey: "HRI@007DigiStall.adminKEY",
    shopId: paymentData.shopId,
    months: paymentData.months,
    planType: paymentData.planType,
  });
}

export async function genarateHash(hashData) {
  const response = await axios.post(`${url}/user/hash`, hashData);
  return response;
}

export async function addRefferal(refferalData) {
  const response = await axios.post(`${url}/user/addref`, refferalData);
  return response;
}

export async function getPayment(paymentData) {
  const response = await axios.post(`${url}/user/test`, paymentData);
  return response;
}

export async function initiatePayment(paymentData) {
  const response = await axios.post(`${url}/user/pay`, paymentData);
  return response;
}

export async function checkPaymentStatus(paymentData) {
  const response = await axios.post(`${url}/user/status`, paymentData);
  return response;
}

export async function getPasswordStatus(kioskcode) {
  const response = await axios.post(`${url}/emitra/password-status`, {
    kioskcode,
  });
  return response;
}

export async function updatePassword(userData) {
  console.log(userData);
  const response = await axios.post(`${url}/emitra/update-password`, userData);
  return response;
}

export async function loginWithPassword(userData) {
  const response = await axios.post(`${url}/emitra/password-check`, userData);
  return response;
}

export async function getEmitraUserData(user) {
  const response = await axios.post(`${url}/emitra/password-check`, user);
  return response;
}

export async function updateDp(userData) {
  const response = await axios.post(`${url}/emitra/update-dp`, userData);
  return response;
}

export async function addStore(storeData) {
  const response = await axios.post(`${url}/emitra/add`, storeData);
  // const response = await axios.post(`http://localhost:5000/emitra/add`, storeData)
  return response;
}

export async function paySubscription(paymentData) {
  const response = await axios.post(`${url}/emitra/pay`, paymentData);
  return response;
}
